import React from "react";
import { Link } from "react-router-dom";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <div>
      <Helmet title={" privacy-policy"}>
      <div
          className="fw-bold col-md-12 "
          style={{ background: "#FBA504", textAlign: "center",  }}
        >
          <p style={{ color: "white",display:"flex", justifyContent:"center", alignItems:"center", }}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center",}}>
          <p>
                <i className="fa fa-envelope me-1 " />
                gpscanans.contact@gmailcom
              </p> 
              <p>
                <i className="fa fa-phone me-1 p-1 m-1" />
                  +1 (779) 256 5291
              </p>
          </div>
          </p>
        </div>
        {/* Navbar & Hero Start */}
        <div className="container-xxl position-relative p-0">
          <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
              {/* <img src="/static/img/logo.png" alt="Logo" /> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-auto py-0">
                <Link to="/" className="nav-item nav-link">
                  Home
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/services" className="nav-item nav-link">
                  Service
                </Link>
                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
              <Link
                to=""
                className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
              >
                Get Started
              </Link>
            </div>
          </nav>
          <div className="container-xxl py-5 bg-primary hero-header">
            <div className="container my-5 py-5 px-lg-5">
              <div className="row g-5 py-5">
                <div className="col-12 text-center">
                  <h1 className="text-white animated slideInDown">
                    Privacy Policy
                  </h1>
                  <hr className="bg-white mx-auto mt-0" style={{ width: 90 }} />
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link className="text-white" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item text-white active"
                        aria-current="page"
                      >
                        Privacy Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navbar & Hero End */}

        {/* About Start */}
        <div className="container-xxl">
          <div className="container  px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <p className="section-title text-secondary">
                  Privacy Policy
                  <span />
                </p>
                <h2 className="">What defines who we are?</h2>
                <p className="">
                  Our website provides information for those who want to upgrade
                  their GPS units but are unsure how to do so.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">gps canans LLC privacy statement</h2>
                <p className="">
                  The privacy of our visitors is a top priority at
                  gps canans.website, which may be reached at{" "}
                  <u>https://gps canans.website </u>
                  gps canans.website collects and records several sorts of
                  information, and this privacy policy explains how we utilize
                  that data. Please feel free to write to us at
                  gps canans.contact@gmailcom if you have any more inquiries or need
                  clarification on our privacy policy. If you have additional
                  questions or require more information about our Privacy
                  Policy, do not hesitate to contact us through email at
                  gps canans.contact@gmailcom
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Data Logs</h2>
                <p className="">
                Log file use is standard practice at gps canans.website. These records keep track of who visits which websites. This is standard practice across all hosting providers' analytics programs. Internet protocol addresses, browser type, Internet service provider, date and time stamps referring/exit sites, and even click counts are among the data that log files gather. These are not connected to any personally identifying information. The information is used for trend analysis, website administration, tracking user behavior on the site, and demographic research.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Information We Gather and How We Use It</h2>
                <p className="">
                gps canans LLC collects information from your interactions with our site to provide you with the best possible experience while using it. This website collects and keeps specific information about its users to process your request on the legal presumption that your agreement was provided when you made a record and agreed to the Privacy Policy. You effectively provide us advice about a piece of this data.
                </p>
                <p> For instance, we may utilize your email address, phone number, address, VIN, and other information to track your record or contact you. Other information we collect depends on your actions while using our sites, such as the sites you access and the conversations you have with our goods. This information includes a record of those partnerships, your IP address, and information about your device.</p>

                <li>To process your request and manage your record, GPS MAP UPDATE collects your information.</li>
                <li>Send you an email with unique recommendations for other products and services we think you'll like.</li>
                <li className="">To prevent fraudulent purchases, we may submit your information to credit reference agencies when we evaluate your request and use the resulting data from those agencies.</li>
                <li>We provide, test, develop, and improve our products and services.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">We use the information to:</h2>
                <li className="py-1 m-1">We distribute, test, tweak, enhance, and personalize our goods and services. Combat spam and other forms of abuse.</li>

                <li className="m-1">Create comprehensive, anonymous use statistics for this website.</li>
                <li className="m-1">When you create a client account, we use the information you provide to process your request and manage your account.</li>
                <li className="m-1">Without your permission, we will never access information from your outsider record.</li>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">How would we keep your data safe?</h2>
                <p className="">
                This site uses outside vendors and facilitating partners for the equipment, programming, systems administration, storage, and associated technology we require to run our site. You give us permission to transport, store, and use your data in any country where we operate or provide our goods or services if you use our services.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Maintenance</h2>
                <p className="">
                This retains personal data associated with your record for the duration of your record, which is far beyond the date of the last admission to your record. You can permanently delete your record if you wish to get rid of your data. After beginning cancellation, deleted account profile pages will produce an error 404 "record not discovered" page and become inaccessible in our framework after fourteen days.
                </p>
                <p>Your personal information may need to be removed from web crawlers for a few additional days, depending on the methods used by those web search tools, over which this website may or may not have any influence. Since this site only has limited secure access to that information, depending on the scenario to provide the sorts of support, you should contact your installment provider to delete any payment or billing data.</p>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Personal Information Collection</h2>
                <p className="">
                We gather specific data about your device, how you interact with the site, and the data required to handle your transactions when you visit the site. If you contact us for customer service, we could also gather further information. This privacy statement refers to any information that may be used to identify a person as "Personal Information." See the list below for further details on the Personal Information we gather and why.
                </p>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Device specifics</h2>
                <li className="py-1 m-1"><b>Examples of the personal data gathered: </b>Web browser version, IP address, time zone, cookie information, the websites or items you visit, search phrases, and how you use the site are all examples of personal information.</li>

                <li className="m-1"><b>The object of the collection: </b>To properly load the website for you and analyze it, use it to improve it.</li>
                <li className="m-1"><b>Origin of the data: </b> By utilizing cookies, log files, web beacons, tags, or pixels to access our site, which automatically collects the information</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Order specifics</h2>
                <li className="py-1 m-1"><b>Examples of the personal data gathered: </b>Name, shipping and billing addresses, contact information (such as credit card numbers), and payment information.</li>

                <li className="m-1"><b>The object of the collection: </b>To fulfill our contract with you by giving you the goods or services you need, processing your payment data, setting up shipping, and sending you invoices and order confirmations. We also need to keep in touch with you, screen our orders for risk or fraud, and, following your preferences, send you information or advertisements about our goods and services.</li>
                <li className="m-1"><b>Origin of the data: </b>  Acquired from you. Information is exchanged with our processor Shopify for commercial purposes.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">COOKIES</h2>
                <p className="">
                When you visit our site, a cookie is a small file of information downloaded to your computer or another device. In addition to functional cookies, we also utilize performance cookies, advertising cookies, social media cookies, and content cookies. By enabling the website to remember your activities and preferences, such as login and region selection, cookies improve your browsing experience.
                </p>
                <p>This means that each time you visit the website or navigate between pages, you won't need to input this information again. Cookies also provide information about how visitors use the website, such as whether they are returning visitors or first-timers.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Children's Information</h2>
                <p className="">
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                </p>
                <p>gps canans.website does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Online Privacy Policy Only</h2>
                <p className="">
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in gps canans.website. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">CONTACT</h2>
                <p className="">
                Contact us by email at gps canans.contact@gmailcom or the address shown below if you want more information about our privacy policies, have questions, or would like to file a complaint.
                </p>
                <p><b>Address:</b> <br /> gps canans LLC <br /> 40 Division St <br /> Trenton, New Jersey 08611 <br /> United States </p> 
                <p>You have the right to file a complaint with the relevant data protection authority if you are not satisfied with our answer to your complaint. You can contact our supervisory authority or your local data protection authority.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Consent</h2>
                <p className="">
                By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
                </p> 
              </div>


            </div>
          </div>
        </div>
        {/* About End */}

        <Footer />
      </Helmet>
    </div>
  );
};

export default Privacy;

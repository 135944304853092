import React from "react";
import { Link } from "react-router-dom";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

const Term = () => {
  return <div>
    <Helmet title={" terms-conditions"}>
    <div
          className="fw-bold col-md-12 "
          style={{ background: "#FBA504", textAlign: "center",  }}
        >
          <p style={{ color: "white",display:"flex", justifyContent:"center", alignItems:"center", }}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center",}}>
          <p>
                <i className="fa fa-envelope me-1 " />
                gpscanans.contact@gmailcom
              </p> 
              <p>
                <i className="fa fa-phone me-1 p-1 m-1" />
                  +1 (779) 256 5291
              </p>
          </div>
          </p>
        </div>
          {/* Navbar & Hero Start */}
          <div className="container-xxl position-relative p-0">
          <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
              {/* <img src="/static/img/logo.png" alt="Logo" /> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-auto py-0">
                <Link to="/" className="nav-item nav-link">
                  Home
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/services" className="nav-item nav-link">
                  Service
                </Link>
                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
              <Link
                to=""
                className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
              >
                Get Started
              </Link>
            </div>
          </nav>
          <div className="container-xxl py-5 bg-primary hero-header">
            <div className="container my-5 py-5 px-lg-5">
              <div className="row g-5 py-5">
                <div className="col-12 text-center">
                  <h1 className="text-white animated slideInDown">
                  Term & Conditions
                  </h1>
                  <hr className="bg-white mx-auto mt-0" style={{ width: 90 }} />
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link className="text-white" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item text-white active"
                        aria-current="page"
                      >
                        Term & Conditions
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navbar & Hero End */}

         {/* About Start */}
         <div className="container-xxl">
          <div className="container  px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <p className="section-title text-secondary">
                Term & Conditions
                  <span />
                </p>
                <h2 className="">OVERVIEW</h2>
                <p className="">
                The GPS Update Store is the company that runs this website. The GPS Update Store is referred to as "we," "us," and "our" throughout the website. You, as the user, are provided by GPS Update Store with access to this website and all information, tools, and services, the provision of which is revoked if you do not comply with any of the terms, conditions, policies, or notifications included herein.
                </p>

                <p>You engage in our Service by browsing our website, buying something from us, or both. By doing so, you agree to be bound via the terms and conditions set out below, including all other terms and conditions and policies linked herein and accessible by hyperlink. All users of the site, including without limitation browsers, suppliers, customers, merchants, and content producers, are subject to these Terms of Service.</p>

                <p>Before using or visiting our website, please take the time to read our Terms of Service thoroughly. You agree to be bound by these Terms of Service by accessing or using any website element. You are not permitted to use any services or visit the website if you do not agree to all of the terms and conditions of this agreement. Acceptance is conditionally set out in these Terms of Service if these Terms of Service represent an offer.</p>

                <p>The Terms of Service also apply to any new features or tools introduced to the current shop. On this page, you may always examine the most recent version of the terms of service. By publishing updates and modifications to our website, we retain the right to amend, modify, or replace any element of these Terms of Service. You must frequently check this page for updates. Following the publishing of such modifications, your continued use or access to the website implies acceptance of those changes.</p>

              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">License</h2>
                <p className="">
                Unless otherwise noted, all content on gps canans.website is the property of gps canans.website and its licensors. The ownership of every intellectual property is reserved. For personal use, you may browse and print pages from https://www.gps canans.website as long as you adhere to the limitations outlined in these terms and conditions.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">You must not:</h2>
                <li>Republish material from <a href="https://gps canans.website/">gps canans.website</a></li>
                <li>Sell, rent, or sub-license material from <a href="https://gps canans.website/">gps canans.website</a></li>
                <li>Reproduce, duplicate or copy material from <a href="https://gps canans.website/">gps canans.website</a></li>
                <p className="">
                Republish material from gps canans.website (unless content is specifically made for redistribution).
                </p>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Limitations of liability and disclaimer of warranties</h2>
                <p className="">
                Your use of our service will not be uninterrupted, timely, secure, or error-free, and we do not promise, represent, or warrant that it will be. We do not guarantee the accuracy or dependability of any outcomes that may be attained via the service. You acknowledge that we reserve the right to discontinue the service without prior warning or to remove it altogether. You explicitly acknowledge that the service is used at your own risk, whether you can use it or not.
                </p>
                <p>Without any representation, warranties, or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement, the service and all products and services delivered to you through the service are provided "as is" and "as available" for your use.</p> 
                <p>Any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability, or otherwise, shall not be made by GPS Update Store, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, or licensors.</p>
                <p>Including, but not restricted to, any inaccuracies or omissions in any content, as well as any loss or harm of any kind sustained as a result of using the service or any content posted, transmitted, or otherwise made accessible via the service, even if the user has been made aware of the likelihood of such events. Our responsibility shall be restricted to the greatest extent by law in those states or jurisdictions that do not allow the exclusion or the restriction of liability for consequential or incidental damages.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">TERMS OF SERVICE CHANGES</h2>
                <p className="">
                You may always consult the most recent version of the Terms of Service on this page. By publishing updates and modifications to our website, we retain the right to amend, modify, or replace any portion of these Terms of Service at any time. You must often check our website for updates. Any modifications to these Terms of Service that are posted after you first use our website or use the service indicate your acceptance of such modifications.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">The Reserved Rights</h2>
                <p className="">
                We retain the right to demand that you take down all links to our website or just one specific link at any time and our sole discretion. Upon such request, you immediately take down any connections to our website. Additionally, we reserve the right to change these terms and conditions and its linking policy at any moment. By continuing to link to our website, you accept to be legally bound by and comply with these linking terms and conditions.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Article Liability</h2>
                <p className="">
                Any content that appears on your website shall not be our responsibility or subject to our liabilities. You undertake to hold us harmless and defend us against any claims based on or resulting from your website. No link(s) may be placed on any page of your website or in any context that contains contents that might be construed as defamatory, offensive, or illegal or that violates or encourages the violation of any third-party rights.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">COMPLETE AGREEMENT</h2>
                <p className="">
                Any right or provision of these Terms of Service that we do not execute or enforce following them does not constitute a waiver of such rights or provisions. Your use of the service is governed by these Terms of Service and any other policies or operating guidelines we post on this website or about the service, which supersedes all prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                </p>
                <p>Any discrepancies in how these Terms of Service should be read should not be used against the party that drafted them.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">CONTACT</h2>
                <p className="">
                Contact us by email at gps canans.contact@gmailcom or the address shown below if you want more information about our privacy policies, have questions, or would like to file a complaint.
                </p>
                <p><b>Address:</b> <br /> gps canans LLC <br /> 40 Division St <br /> Trenton, New Jersey 08611 <br /> United States </p> 
              </div>



              
            </div>
          </div>
        </div>
        {/* About End */}

        <Footer/>
    </Helmet>
  </div>;
};

export default Term;

import React from "react";

const Feature = () => {
  return (
    <div>
      {/* Feature Start */}
      <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
          <div className="row g-4">
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="feature-item bg-light rounded text-center p-4">
                <i className="fa fa-3x fa-solid fa-car text-primary mb-4" />
                <h5 className="mb-3">Cars</h5>
                <p className="m-0">
                  Discover reliable car GPS services for seamless navigation.
                  Get accurate directions and real-time updates for your
                  journeys. Explore now!
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="feature-item bg-light rounded text-center p-4">
                <i className="fa fa-3x fa-solid fa-truck text-primary mb-4" />
                <h5 className="mb-3">Truck</h5>
                <p className="m-0">
                  Experience top-notch truck GPS services for efficient
                  navigation. Get reliable routes and real-time updates for your
                  trucking needs. Explore now!
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="feature-item bg-light rounded text-center p-4">
                <i className="fa fa-3x fa-solid fa-motorcycle text-primary mb-4" />
                <h5 className="mb-3">Motercycle</h5>
                <p className="m-0">
                  Rev up your motorcycle adventures with our premium GPS
                  services. Enjoy precise navigation and real-time updates on
                  the road. Discover now!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature End */}
    </div>
  );
};

export default Feature;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/* Footer Start */}
      <div
        className="container-fluid bg-primary text-light footer wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5 px-lg-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Address
                <span />
              </p>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                40 Division St, Trenton, New Jersey 08611
              </p>
              {/* <p>
                <i className="fa fa-phone-alt me-3" />
                +012 345 67890
              </p> */}
              <p>
                <i className="fa fa-envelope me-3" />
                gps canans.contact@gmailcom
              </p>

              <p>
                <i className="fa fa-phone me-3" />
                +1 (779) 256 5291
              </p>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Quick Link
                <span />
              </p>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/services">
                Services
              </Link>
              <Link className="btn btn-link" to="/refund-policy">
              Refund Policy
              </Link>
              <Link className="btn btn-link" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="btn btn-link" to="/terms-conditions">
              Terms & Conditions
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Gallery
                <span />
              </p>
              <div className="row g-2">
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-1.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-2.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-4.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-3.png"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-2.png"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-1.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                gps canans
                <span />
              </p>
              <p>
                Welcome to our GPS service website! Navigate with ease and
                precision using our cutting-edge GPS technology. Get accurate
                directions and real-time updates for a seamless travel
                experience. Explore now!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

const Refund = () => {
  return <div>
       <Helmet title={" refund-policy"}>
       <div
          className="fw-bold col-md-12 "
          style={{ background: "#FBA504", textAlign: "center",  }}
        >
          <p style={{ color: "white",display:"flex", justifyContent:"center", alignItems:"center", }}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center",}}>
          <p>
                <i className="fa fa-envelope me-1 " />
                gpscanans.contact@gmailcom
              </p> 
              <p>
                <i className="fa fa-phone me-1 p-1 m-1" />
                  +1 (779) 256 5291
              </p>
          </div>
          </p>
        </div>
        {/* Navbar & Hero Start */}
        <div className="container-xxl position-relative p-0">
          <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
              {/* <img src="/static/img/logo.png" alt="Logo" /> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-auto py-0">
                <Link to="/" className="nav-item nav-link">
                  Home
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/services" className="nav-item nav-link">
                  Service
                </Link>
                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
              <Link
                to=""
                className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
              >
                Get Started
              </Link>
            </div>
          </nav>
          <div className="container-xxl py-5 bg-primary hero-header">
            <div className="container my-5 py-5 px-lg-5">
              <div className="row g-5 py-5">
                <div className="col-12 text-center">
                  <h1 className="text-white animated slideInDown">
                  Refund Policy
                  </h1>
                  <hr className="bg-white mx-auto mt-0" style={{ width: 90 }} />
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link className="text-white" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item text-white active"
                        aria-current="page"
                      >
                        Refund Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navbar & Hero End */}

        {/* About Start */}
        <div className="container-xxl">
          <div className="container  px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <p className="section-title text-secondary">
                Refund Policy
                  <span />
                </p>
                <p className="">
                Thank you for shopping with gps canans for GPS devices and software maintenance. We take pride in offering top-quality products and services to our valued customers. However, we understand that sometimes a refund may be necessary. This Refund Policy outlines the guidelines and procedures for obtaining a refund for our GPS devices and software maintenance. Please read this policy carefully before making a purchase.
                </p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className=""> Refund Eligibility for GPS Devices:</h2>
                <p className="">
                We offer a 30-day money-back guarantee for our GPS devices. To be eligible for a refund, the following conditions must be met:
                </p>
                <li>The GPS device must be in its original condition, undamaged, and with all its original packaging and accessories included.</li>
                <li>The 30-day refund period starts from the date of delivery.</li>
                <li>A proof of purchase, such as an order number or receipt, must be provided.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Refund Process for GPS Devices:</h2>
                <p className="">
                If you wish to request a refund for a GPS device, please follow these steps:
                </p>
                <li>Contact our customer support team at gps canans.contact@gmailcom within the 30-day refund period and provide your order number and reason for the return.</li>
                <li>Our customer support team will review your request and provide you with instructions on how to return the product.</li>
                <li>Once we receive the returned GPS device and verify its condition, we will process the refund. Please allow up to [number of days] for the refund to be credited back to your original payment method.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className=""> Refund Eligibility for Software Maintenance:</h2>
                <p className="">
                We offer software maintenance services for our GPS devices to ensure they operate at their best. Refund eligibility for software maintenance is subject to the following conditions:
                </p>

                <li>A refund can be requested within 7 days of the purchase date of the software maintenance plan.</li>
                <li>No software updates or support services should have been utilized during the 7-day period.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Refund Process for Software Maintenance:</h2>
                <p>To request a refund for software maintenance, please follow these steps:</p>
                <li className="py-1 m-1">Contact our customer support team at gps canans.contact@gmailcom within the 7-day refund period and provide your order number and reason for the refund.</li>

                <li className="m-1">Our customer support team will verify your eligibility for a refund based on the usage of software updates and support services.</li>
                <li className="m-1">If eligible, we will process the refund within [number of days] to the original payment method</li>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Non-Refundable Items:</h2>
                <p className="">
                The following items are not eligible for a refund:
                </p>
                <li>Downloadable software or digital products that have been accessed or used.</li>
                <li>Products with damaged or missing packaging, accessories, or serial numbers.</li>
                <li>Services that have already been provided, such as personalized support or training.</li>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Shipping Costs:</h2>
                <p className="">
                Unless the return is due to a defect or an error on our part, shipping costs for returned products are the responsibility of the customer.
                </p>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Personal Information Collection</h2>
                <p className="">
                We gather specific data about your device, how you interact with the site, and the data required to handle your transactions when you visit the site. If you contact us for customer service, we could also gather further information. This privacy statement refers to any information that may be used to identify a person as "Personal Information." See the list below for further details on the Personal Information we gather and why.
                </p>
              </div>


              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Exchanges:</h2>
                <p className="py-1 m-1">We do not offer direct product exchanges. If you wish to exchange a product, please follow the refund process and then place a new order for the desired item.</p>
              </div>

              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">Cancellation of Orders</h2>
               <p>If you wish to cancel your order before it has been shipped, please contact our customer support team as soon as possible. If the order has already been shipped, you will need to follow the refund process once the product is received.</p>
              </div>
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2 className="">CONTACT</h2>
                <p className="">
                Contact us by email at gps canans.contact@gmailcom or the address shown below if you want more information about our privacy policies, have questions, or would like to file a complaint.
                </p>
                <p><b>Address:</b> <br /> gps canans LLC <br /> 40 Division St <br /> Trenton, New Jersey 08611 <br /> United States </p> 
                <p>You have the right to file a complaint with the relevant data protection authority if you are not satisfied with our answer to your complaint. You can contact our supervisory authority or your local data protection authority.</p>
              </div>

            </div>
          </div>
        </div>
        {/* About End */}

        <Footer />
      </Helmet>
  </div>;
};

export default Refund;

import React from "react";

const AboutComp = () => {
  return (
    <div>
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="section-title text-secondary">
                About Us
                <span />
              </p>
              <h2 className="mb-">
                GPS Service Solution Provider with over 10 years of experience
              </h2>
              <p className="mb-4">
                Welcome to the leading GPS service solution provider with an
                impressive track record of over 10 years of experience. We take
                pride in offering cutting-edge GPS solutions that cater to
                diverse industries and individual needs. Our team of experts
                combines expertise and innovation to deliver reliable and
                efficient GPS services. Whether you're a fleet manager,
                logistics professional, or an adventure seeker, we have the
                perfect solution for you. From precise navigation and real-time
                tracking to advanced features and seamless integration, our
                comprehensive offerings ensure optimal performance and enhanced
                productivity. Join thousands of satisfied customers who rely on
                us for their GPS service needs. Experience excellence with our
                GPS service solution provider today.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="/static/img/img-1.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <div className="container-xxl">
        <div className="container px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="/static/img/img-3.jpg"
              />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="section-title text-secondary">
                About Us
                <span />
              </p>
              <h2 className="mb-3">Best Service Provider in USA & Canada</h2>
              <p className="mb-4">
                Welcome to the best service provider in the USA & Canada, where
                excellence meets customer satisfaction. With a commitment to
                delivering exceptional services, we have earned a reputation as
                the go-to choice for individuals and businesses alike. Our
                dedicated team of professionals goes above and beyond to ensure
                that your needs are met with utmost precision and care. From
                prompt responses and reliable solutions to personalised
                attention and seamless experiences, we strive to exceed
                expectations at every turn. With a wide range of services
                tailored to suit your requirements, trust us to be your trusted
                partner for all your service needs. Experience the best service
                provider in the USA & Canada today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="section-title text-secondary">
                About Us
                <span />
              </p>
              <h2 className="mb-3">
                Best Service Provider in Australia & New Zealand
              </h2>
              <p className="mb-4">
                Welcome to the #3 Best Service Provider in Australia & New
                Zealand, where exceptional service meets unparalleled customer
                satisfaction. We take immense pride in our ranking and strive to
                continuously exceed expectations. With a dedicated team of
                professionals, we offer a wide range of services tailored to
                meet your unique needs. From prompt and reliable solutions to
                personalised attention and seamless experiences, we are
                committed to delivering excellence in every interaction. Whether
                you require assistance in technology, logistics, or any other
                sector, trust us to be your reliable partner. Discover why we're
                ranked among the best service providers in Australia & New
                Zealand and experience our unmatched service today.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="/static/img/img-4.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComp;
